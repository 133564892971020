// import axios from 'axios';
// import { useForm } from 'react-hook-form';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { useEffect } from 'react';

// const DonationForm = () => {
//   const { register, handleSubmit, setValue, formState: { errors } } = useForm();
//   const navigate = useNavigate();
//   const location = useLocation();


//   // Extract the amount from the passed state
//   const { amount } = location.state || { amount: 0 };  // Default to 0 if not provided

//   // Set the amount in the form when the component loads
//   useEffect(() => {
//     setValue('amount', amount);  // Set the amount field with the value passed from the state
//   }, [amount, setValue]);

//   const onSubmit = async (data) => {
//     const tran_id = new Date().getTime().toString();
//     const paymentData = {
//       ...data,
//       tran_id: tran_id,
//       success_url: 'https://localhost:8000/payments/success/',
//       fail_url: 'https://localhost:8000/payments/fail/',
//       cancel_url: 'https://localhost:8000/payments/cancel/',
//       ipn_url: 'https://localhost:8000/payments/ipn/',
//     };
//     try {
//       const response = await axios.post(
//         'https://localhost:8000/payments/initiate/',
//         paymentData,
//         {
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         },
//       );
//       if (response.data.payment_url) {
//         window.location.href = response.data.payment_url;
//       } else {
//         console.error('Payment initiation failed:', response.data);
//       }
//     } catch (error) {
//       console.error('Error initiating payment:', error);
//     }
//   };

//   return (
//     <div className="d-flex flex-column align-items-center justify-content-center min-vh-100 bg-light">
//       <div className="card p-4 shadow w-100 max-w-md">
//         <h2 className="card-title text-center mb-4">SSLCOMMERZ Payment form</h2>
//         <form onSubmit={handleSubmit(onSubmit)} className="w-100">
//           {/* Predefined Amount */}
//           <div className="mb-3">
//             <input
//               type="number"
//               placeholder="Amount"
//               {...register('amount', { required: 'Amount is required' })}
//               className="form-control"
//               readOnly
//             />
//             {errors.amount && <p className="text-danger">{errors.amount.message}</p>}
//           </div>
//           <div className="mb-3">
//             <input
//               type="text"
//               placeholder="Currency"
//               defaultValue="BDT"
//               {...register('currency', { required: 'Currency is required' })}
//               className="form-control"
//             />
//             {errors.currency && <p className="text-danger">{errors.currency.message}</p>}
//           </div>
//           <div className="mb-3">
//             <input
//               type="text"
//               placeholder="Customer Name"
//               {...register('cus_name', { required: 'Customer Name is required' })}
//               className="form-control"
//             />
//             {errors.cus_name && <p className="text-danger">{errors.cus_name.message}</p>}
//           </div>
//           <div className="mb-3">
//             <input
//               type="email"
//               placeholder="Customer Email"
//               {...register('cus_email', { required: 'Customer Email is required' })}
//               className="form-control"
//             />
//             {errors.cus_email && <p className="text-danger">{errors.cus_email.message}</p>}
//           </div>
//           <div className="mb-3">
//             <input
//               type="text"
//               placeholder="Customer Phone"
//               {...register('cus_phone', { required: 'Customer Phone is required' })}
//               className="form-control"
//             />
//             {errors.cus_phone && <p className="text-danger">{errors.cus_phone.message}</p>}
//           </div>
//           <div className="mb-3">
//             <input
//               type="text"
//               placeholder="Customer Address"
//               {...register('cus_address', { required: 'Customer Address is required' })}
//               className="form-control"
//             />
//             {errors.cus_address && <p className="text-danger">{errors.cus_address.message}</p>}
//           </div>
//           <div className="mb-3">
//             <input
//               type="text"
//               placeholder="Reference"
//               {...register('reference', { required: 'Reference is required' })}
//               className="form-control"
//             />
//             {errors.reference && <p className="text-danger">{errors.reference.message}</p>}
//           </div>
//           <div className="mb-3">
//             <input
//               type="text"
//               placeholder="City"
//               {...register('cus_city', { required: 'City is required' })}
//               className="form-control"
//             />
//             {errors.city && <p className="text-danger">{errors.city.message}</p>}
//           </div>
//           <div className="mb-3">
//             <input
//               type="text"
//               placeholder="Country"
//               {...register('cus_country', { required: 'Country is required' })}
//               className="form-control"
//             />
//             {errors.country && <p className="text-danger">{errors.country.message}</p>}
//           </div>
//           <button type="submit" className="btn btn-primary w-100">
//             Pay Now
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default DonationForm;


import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
// import {  } from 'react';

const DonationForm = () => {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [isAgreed, setIsAgreed] = useState(false);


  

    // Extract the amount from the passed state
    const { amount } = location.state || { amount: 0 };  // Default to 0 if not provided
    const { address } = location.state || { address: null };
    const { city } = location.state || { city: null };
    const { country } = location.state || { country: null }; 
    const { name } = location.state || { name: null }; 
    const { phone_number } = location.state || { phone_number: null }; 
    const { email_address } = location.state || { email_address: null }; 
  console.log(location);

    useEffect(() => {
      setValue('amount', amount);
      setValue('cus_address', address);
      setValue('cus_city', city);
      setValue('cus_country', country);
      setValue('cus_name', name);
      setValue('cus_phone', phone_number);
      setValue('cus_email', email_address);
      // ... and so on for other fields
    }, [amount, address, city,country,name,phone_number,email_address, setValue]);

  const onSubmit = async (data) => {
    if (!isAgreed) {
      alert('Please agree to the terms and conditions before proceeding.');
      return;
    }
    const tran_id = new Date().getTime().toString();
    const paymentData = {
      ...data,
      tran_id: tran_id,
      success_url: 'https://api.cseduaa.org/payments/success/',
      fail_url: 'https://api.cseduaa.org/payments/fail/',
      cancel_url: 'https://api.cseduaa.org/payments/cancel/',
      ipn_url: 'https://api.cseduaa.org/payments/ipn/',
    };
    try {
      const response = await axios.post(
        'https://api.cseduaa.org/payments/initiate/',
        paymentData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.data.payment_url) {
        window.location.href = response.data.payment_url;
      } else {
        console.error('Payment initiation failed:', response.data);
      }
    } catch (error) {
      console.error('Error initiating payment:', error);
    }
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center min-vh-100 bg-light">
      <div className="card p-4 shadow w-100 max-w-md">
        <h2 className="card-title text-center mb-4">SSLCOMMERZ Payment form</h2>
        <form onSubmit={handleSubmit(onSubmit)} className="w-100">
          {/* Predefined Amount */}
          <div className="mb-3">
            <input
              type="number"
              placeholder="Amount"
              {...register('amount', { required: 'Amount is required' })}
              className="form-control"
            />
            {errors.amount && <p className="text-danger">{errors.amount.message}</p>}
          </div>
          <div className="mb-3">
            <input
              type="text"
              placeholder="Currency"
              defaultValue="BDT"
              {...register('currency', { required: 'Currency is required' })}
              className="form-control"
              readOnly
            />
            {errors.currency && <p className="text-danger">{errors.currency.message}</p>}
          </div>
          <div className="mb-3">
            <input
              type="text"
              placeholder="Customer Name"
              {...register('cus_name', { required: 'Customer Name is required' })}
              className="form-control"
            />
            {errors.cus_name && <p className="text-danger">{errors.cus_name.message}</p>}
          </div>
          <div className="mb-3">
            <input
              type="email"
              placeholder="Customer Email"
              {...register('cus_email', { required: 'Customer Email is required' })}
              className="form-control"
            />
            {errors.cus_email && <p className="text-danger">{errors.cus_email.message}</p>}
          </div>
          <div className="mb-3">
            <input
              type="text"
              placeholder="Customer Phone"
              {...register('cus_phone', { required: 'Customer Phone is required' })}
              className="form-control"
            />
            {errors.cus_phone && <p className="text-danger">{errors.cus_phone.message}</p>}
          </div>
          <div className="mb-3">
            <input
              type="text"
              placeholder="Customer Address"
              {...register('cus_address', { required: 'Customer Address is required' })}
              className="form-control"
            />
            {errors.cus_address && <p className="text-danger">{errors.cus_address.message}</p>}
          </div>
          <div className="mb-3">
            <input
              type="text"
              placeholder="Reference"
              {...register('reference', { required: 'Reference is required' })}
              className="form-control"
            />
            {errors.reference && <p className="text-danger">{errors.reference.message}</p>}
          </div>
          <div className="mb-3">
            <input
              type="text"
              placeholder="City"
              {...register('cus_city', { required: 'City is required' })}
              className="form-control"
            />
            {errors.city && <p className="text-danger">{errors.city.message}</p>}
          </div>
          <div className="mb-3">
            <input
              type="text"
              placeholder="Country"
              {...register('cus_country', { required: 'Country is required' })}
              className="form-control"
            />
            {errors.country && <p className="text-danger">{errors.country.message}</p>}
          </div>
          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="termsCheckbox"
              checked={isAgreed}
              onChange={() => setIsAgreed(!isAgreed)}
            />
            <label className="form-check-label" htmlFor="termsCheckbox">
              I HAVE READ AND AGREE TO THE WEBSITE'S TERMS AND CONDITIONS, PRIVACY POLICY, REFUND POLICY
            </label>
          </div>
          <button type="submit" className="btn btn-primary w-100">
            Pay Now
          </button>
        </form>
      </div>
    </div>
  );
};

export default DonationForm;

